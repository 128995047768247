import { useEffect } from "react";
import axios from "axios";
import { Apis } from './index';

export const HotDeals = ({ onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        const fetchHotdeals = async () => {
            try {
                const response = await axios.get(Apis.HotDeals, {
                    headers: { 'Authorization': apiKey }
                });
                onFetch(response.data.packages);
            } catch (error) {
                console.error(error);
                onFetch([], false);
            }
        };
        fetchHotdeals();
    }, [apiKey, onFetch]);

    return null;
}
export const AllPackages = ({ onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        const fetchAllPackage = async () => {
            try {
                const response = await axios.get(Apis.AllPackages, {
                    headers: { 'Authorization': apiKey }
                });
                onFetch(response.data);
            } catch (error) {
                console.error(error);
                onFetch([], false);
            }
        };
        fetchAllPackage();
    }, [apiKey, onFetch]);

    return null;
}