import React from "react";
import "./packages.css";
import Navbar from "../../Components/Navbar/navbar";
import Subscribe from "../../Components/Subscribe/subscribe";
import Footer from "../../Components/Footer/footer";
import Featured from "../../Components/Featuredin/faturedin";
import AllPackages from "../../Components/SearchPackage/searchpackage";

export default function Packages() {
    return (
        <>
            <Navbar /> 
            <section className="breadcrumbarea">
                <div className="container">
                    <div className="breadcrumtitle">
                        <h2>Packages</h2>
                    </div>
                </div>
            </section>
            <section className="promotiontext">
                <div className="promotioncontent">
                    <p>Book 2025's awesome trip today and save up to 25%*. Plus plenty of time to pay in full.</p>
                </div>
            </section>
            <AllPackages/>
            <Subscribe />
            <Featured />
            <Footer />
        </>
    );
}