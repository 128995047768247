import React, {useState} from "react";
import "./destination.css";
import Navbar from "../../Components/Navbar/navbar";
import Subscribe from "../../Components/Subscribe/subscribe";
import Footer from "../../Components/Footer/footer";
import Featured from "../../Components/Featuredin/faturedin";
import { Link } from "react-router-dom";
import { TrandingDestinations } from "../../Api/destinations";

export default function Packages() {
    const [destinations, setDestinations] = useState([]);
    return (
        <>
            <Navbar />
            <section className="breadcrumbarea">
                <div className="container">
                    <div className="breadcrumtitle">
                        <h2>Trending Destinations</h2>
                    </div>
                </div>
            </section>
            <section className="promotiontext">
                <div className="promotioncontent">
                    <p>Browse our trending destinations</p>
                </div>
            </section>
            <section className="destinationlist">
                <div className="container-fluid">
                    <div className="w90">
                        <div className="row">
                            <TrandingDestinations onFetch={setDestinations} />
                            {destinations.map((destination) => destination && (
                                <div className="customdesigrid">
                                    <div className="destinationsgrid">
                                        <Link to={destination.regison_url+'/?from=uk'} target="_blank">
                                            <div className="destigridimg">
                                                <img src={destination.regison_image} alt={destination.regison_name} />
                                            </div>
                                            <div className="destigridhead">
                                                <h2>{destination.regison_name}</h2>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <Subscribe />
            <Featured />
            <Footer />
        </>
    );
}