import React, {useEffect, useState} from "react";
import "./testimonials.css";
import { Link } from "react-router-dom";
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FaChevronRight, FaChevronLeft, FaStar } from "react-icons/fa";
import axios from "axios";
import { Apis } from '../../Api/index';
import SkeletonLoader from "../Skeleton_loader/skeleton"

export default function Testimonials(){
    const [testimonials, setTestimonials] = useState([null]);
    const [loading, setLoading] = useState(true);
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        const fetchTestimonials = async () => {
            try {
                const response = await axios.get(Apis.Testimonials, {
                    headers: { 'Authorization': apiKey }
                });
                setTestimonials(response.data.testimonials);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };
        fetchTestimonials();
    }, [apiKey]);
    return(
        <>
        <section className="testimonails">
            <div className="container-fluid">
                <div className="w90">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="testimonailheaidng">
                                <h2>Testimonials</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="w90">
                    <div className="testimonaildata">
                        {!loading ? (
                            <>
                            <div className="testimonial-prev navarrowprevbottom"><FaChevronLeft/></div>
                            <div className="testimonial-next navarrownextbottom"><FaChevronRight/></div>
                            <Swiper modules={[Navigation, Pagination, A11y]} spaceBetween={50} slidesPerView={5} pagination={{ clickable: true }}
                            breakpoints={{
                                0: {
                                slidesPerView: 1,
                                },
                                400: {
                                slidesPerView: 1,
                                },
                                639: {
                                slidesPerView: 2,
                                },
                                865: {
                                slidesPerView: 2,
                                },
                                1000: {
                                slidesPerView: 3,
                                },
                                1500: {
                                slidesPerView: 3,
                                },
                                1700: {
                                slidesPerView: 3,
                                }
                            }}
                            navigation={{
                                prevEl: '.testimonial-prev',
                                nextEl: '.testimonial-next',
                            }}
                            >
                                {testimonials.map((testimonial) => (
                                <SwiperSlide key={testimonial.id}>
                                    <div className="testimonialbox">
                                        <div className="testimonialstar">
                                            {[...Array(5)].map((_, index) => (
                                                <FaStar key={index} />
                                            ))}
                                        </div>
                                        <div className="reviername">
                                            <h2>{testimonial.author_name}</h2>
                                        </div>
                                        <div className="reviewcontetn">
                                            <p dangerouslySetInnerHTML={{ __html: testimonial.testi_content || '' }}></p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                ))}
                            </Swiper>
                            </>
                        ): (
                            <>
                            <SkeletonLoader count={3} />
                            </>
                        )}
                    </div>
                    <div className="testimonialbtns">
                        <Link to="https://fayyaztravels.com/testimonials/?from=uk" target="_blank">Read More</Link>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}