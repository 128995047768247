import { useState, useEffect, useCallback } from "react";
import SkeletonLoader from "../Skeleton_loader/skeleton";
import InstagramFeed from "../../Api/instagram";
import "./instagram.css";
import FevIcon from "../../assets/fev.png";
import { Link } from "react-router-dom";

export default function Instagram(){
    const [Instafeed, setInstagram] = useState([]);
    const [loading, setLoading] = useState(true);
    const handleFetch = useCallback((data) => {
        setInstagram(data || []);
        setLoading(false);
    }, []);
    useEffect(() => {
        setLoading(true);
    }, []);
    return(
        <>
        <InstagramFeed onFetch={handleFetch} />
        <section className="instagram">
            <div className="container-fluid">
                <div className="w90">
                    <div className="instaheading">
                        <h2>Follow Us On Instagram</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid p0">
                <div className="instahead">
                    <div className="instaimgtag">
                        <div className="instaimglogo">
                            <img src={FevIcon} alt="Instagram Logo" />
                        </div>
                        <div className="instanametag">
                            <h2><a href="https://www.instagram.com/fayyaztravels" target="_blank" rel="noreferrer">Fayyaz Travels</a></h2>
                            <p><a href="https://www.instagram.com/fayyaztravels" target="_blank" rel="noreferrer">@fayyaztravels</a></p>
                        </div>
                    </div>
                    <div className="instatotalpost">
                        <h2>{Instafeed?.totalpost?.toLocaleString() || '0'}</h2>
                        <p>Posts</p>
                    </div>
                    <div className="instatotalpost">
                        <h2>7,014</h2>
                        <p>Followers</p>
                    </div>
                    <div className="instatotalpost">
                        <h2>2,971</h2>
                        <p>Following</p>
                    </div>
                    <div className="instafollowbtn">
                        <a href="https://www.instagram.com/fayyaztravels" target="_blank" rel="noreferrer">Follow</a>
                    </div>
                </div>
                <div className="instapost">
                    {!loading ? (
                        <div className="instarow">
                            {Instafeed.instagram && Instafeed.instagram.length > 0 ? (
                                Instafeed.instagram.map((Instafeeds, index) => 
                                    Instafeeds && (
                                        <div className="colom-5" key={index}>
                                            <div className="instagbox">
                                                <Link to={Instafeeds.permalink} target="_blank" rel="noreferrer">
                                                    <div className="instagramimgbox">
                                                        <img src={Instafeeds.thumbnail_url} alt="Insta post"/>
                                                    </div>
                                                    <div className="istafeed">
                                                        <p>{Instafeeds.caption}</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    )
                                )
                            ) : (
                                <p className="text-center">No Instagram posts available.</p>
                            )}
                        </div>
                    ) : (
                        <SkeletonLoader count={4} />
                    )}
                </div>
            </div>   
        </section>
        </>
    )
}