import React from "react";
import "./whychoose.css";
import { Link } from "react-router-dom";
import { ReactComponent as Whychooseone } from "../../assets/whychoose_one.svg";
import { ReactComponent as Whychoosetwo } from "../../assets/whychoose_two.svg";
import { ReactComponent as Whychoosethree } from "../../assets/whychoose_three.svg";
import { ReactComponent as Whychoosefour } from "../../assets/whychoose_four.svg";

export default function Whychoose(){
    return(
        <>
        <section className="whychoose">
            <div className="container-fluid">
                <div className="w90">
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <div className="whychooseheading">
                                <h2>Why Choose Us</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="w90">
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 col-6">
                            <div className="whychoosebox">
                                <div className="whychooseicon"><Whychooseone/></div>
                                <div className="whychoosetitle">
                                    <h2>We know our stuff!</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 col-6">
                            <div className="whychoosebox">
                                <div className="whychooseicon"><Whychoosetwo/></div>
                                <div className="whychoosetitle">
                                    <h2>Bespoke and personalised</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 col-6">
                            <div className="whychoosebox">
                                <div className="whychooseicon"><Whychoosethree/></div>
                                <div className="whychoosetitle">
                                    <h2>End to end services</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 col-6">
                            <div className="whychoosebox">
                                <div className="whychooseicon"><Whychoosefour/></div>
                                <div className="whychoosetitle">
                                    <h2>Safety and privacy</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="whybtnsbottom">
                        <Link to="https://fayyaztravels.com/about/?from=uk">Learn More</Link>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}