import React from "react";
import { Link } from "react-router-dom";
import "./services.css";
import { Navigation, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { ServiceData } from "./servicedata";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

export default function Services(){
    return(
        <>
        <section className="servicesarea">
            <div className="container-fluid">
                <div className="w90">
                    <div className="row align-items-center">
                        <div className="col-lg-8">
                            <div className="servicesheading">
                                <h2>Our Services</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="w90">
                    <div className="serviceslider">
                        <div className="service-prev navarrowprev blackcolor"><FaChevronLeft/></div>
                        <div className="service-next navarrownext blackcolor"><FaChevronRight/></div>
                        <Swiper
                            modules={[Navigation, A11y]}
                            spaceBetween={50}
                            slidesPerView={5}
                            navigation={{
                              prevEl: '.service-prev',
                              nextEl: '.service-next',
                            }}
                            breakpoints={{
                            0: { slidesPerView: 1 },
                            400: { slidesPerView: 2 },
                            639: { slidesPerView: 3 },
                            865: { slidesPerView: 3 },
                            1000: { slidesPerView: 4 },
                            1500: { slidesPerView: 4 },
                            1700: { slidesPerView: 4 },
                            }}
                        >
                            {ServiceData.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className={item.service_class}>
                                        <Link to={item.service_url+'?from=uk'} target="_blank">
                                            <div className="serviceicon">{item.service_img}</div>
                                            <div className="serviceheading"><h2>{item.service_name}</h2></div>
                                        </Link>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}