import { ReactComponent as CorporateIcon } from "../../assets/corporateservice.svg";
import { ReactComponent as InboundIcon } from "../../assets/inbound.svg";
import { ReactComponent as WorldWideIcon } from "../../assets/worldwide.svg";
import { ReactComponent as VisaIcon } from "../../assets/visaservice.svg";
import { ReactComponent as CarIcon } from "../../assets/carrent.svg";
import { ReactComponent as LuxuryIcon } from "../../assets/luxuryservice.svg";
import { ReactComponent as PrivateIcon } from "../../assets/PrivateJets.svg";
import { ReactComponent as SupportIcon } from "../../assets/support.svg";
import { ReactComponent as CustomizedIcon } from "../../assets/newcustom.svg";
import { ReactComponent as CruiseIcon } from "../../assets/cruise.svg";

export const ServiceData = [
    {
        service_name: 'Inbound / Outbound Tourism',
        service_img: <InboundIcon/>,
        service_url: 'https://fayyaztravels.com/packages/',
        service_class: 'servicegrid fillcolor'
    },
    {
        service_name: 'Overseas / Local Accommodation',
        service_img: <CorporateIcon/>,
        service_url: 'https://fayyaztravels.com/contact-us/',
        service_class: 'servicegrid fillcolor'
    },
    {
        service_name: 'Worldwide Flight Ticketing',
        service_img: <WorldWideIcon/>,
        service_url: 'https://fayyaztravels.com/contact-us/',
        service_class: 'servicegrid fillcolor'
    },
    {
        service_name: 'Customized Itinerary Planning',
        service_img: <CustomizedIcon/>,
        service_url: 'https://fayyaztravels.com/contact-us/',
        service_class: 'servicegrid'
    },
    {
        service_name: 'Visa Services',
        service_img: <VisaIcon/>,
        service_url: 'https://fayyaztravels.com/visa/',
        service_class: 'servicegrid'
    },
    {
        service_name: 'Car Rentals',
        service_img: <CarIcon/>,
        service_url: 'https://fayyaztravels.com/contact-us/',
        service_class: 'servicegrid fillcolor'
    },
    {
        service_name: 'Event Management',
        service_img: <CorporateIcon/>,
        service_url: 'https://fayyaztravels.com/contact-us/',
        service_class: 'servicegrid fillcolor'
    },
    {
        service_name: 'Luxury Travel Services',
        service_img: <LuxuryIcon/>,
        service_url: 'https://fayyaztravels.com/tailor-made-luxury-holidays/',
        service_class: 'servicegrid fillcolor'
    },
    {
        service_name: 'Corporate Travel Packages',
        service_img: <CorporateIcon/>,
        service_url: 'https://fayyaztravels.com/corporate-travel-agents-singapore/',
        service_class: 'servicegrid fillcolor'
    },
    {
        service_name: 'Rail/Cruise Transfers',
        service_img: <CruiseIcon/>,
        service_url: 'https://fayyaztravels.com/contact-us/',
        service_class: 'servicegrid fillcolor'
    },
    {
        service_name: 'Private Jets',
        service_img: <PrivateIcon/>,
        service_url: 'https://fayyaztravels.com/tailor-made-luxury-holidays/',
        service_class: 'servicegrid fillcolor'
    },
    {
        service_name: 'Special Meals',
        service_img: <CorporateIcon/>,
        service_url: 'https://fayyaztravels.com/contact-us/',
        service_class: 'servicegrid fillcolor'
    },
    {
        service_name: 'Religious Tours',
        service_img: <CorporateIcon/>,
        service_url: 'https://fayyaztravels.com/contact-us/',
        service_class: 'servicegrid fillcolor'
    },
    {
        service_name: '24/7 Support',
        service_img: <SupportIcon/>,
        service_url: 'https://fayyaztravels.com/contact-us/',
        service_class: 'servicegrid fillcolor'
    }
];
