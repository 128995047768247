import React, {useState} from "react";
import { Link } from "react-router-dom";
import "./tranding.css";
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FaAngleRight } from "react-icons/fa";
import { TrandingDestinations } from "../../Api/destinations";

export default function Tranding(){
    const [destinations, setDestinations] = useState([]);
    return(
        <>
        <section className="tranding">
            <div className="container-fluid">
                <div className="w90">
                    <div className="row align-items-center">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7 col-7">
                            <div className="trandingheading">
                                <h2>Trending Destinations</h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-5 col-5">
                            <div className="trandingbtns">
                                <Link to='/destinations/'>View All</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="w90">
                    <div className="trandingdata">
                        <Swiper modules={[Navigation, Pagination, A11y]} spaceBetween={50} slidesPerView={5} navigation pagination={{ clickable: true }} 
                        breakpoints={{
                            0: {
                              slidesPerView: 1,
                            },
                            375: {
                              slidesPerView: 2
                            },
                            400: {
                              slidesPerView: 2,
                            },
                            639: {
                              slidesPerView: 3,
                            },
                            865: {
                              slidesPerView: 4,
                            },
                            1024: {
                              slidesPerView: 3,
                            },
                            1440: {
                              slidesPerView: 5,
                            },
                            1700: {
                              slidesPerView: 5,
                            }
                          }}
                          >
                            <TrandingDestinations onFetch={setDestinations} />
                            {destinations.map((destination) => destination && (
                                <SwiperSlide key={destination.id}>
                                    <div className="trandingdest">
                                        <Link to={destination.regison_url+'/?from=uk'} target="_blank">
                                            <div className="trandingdesthead">
                                                <h2>{destination.regison_name}</h2>
                                            </div>
                                            <div className="trandingdestimg">
                                                <img src={destination.regison_image} alt={destination.regison_name} />
                                            </div>
                                            <div className="traingicons"><FaAngleRight/></div>
                                        </Link>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}