import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import "./hotdeals.css";
import { Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Calender from "../../assets/calender.svg";
import City from "../../assets/city.svg";
import Location from "../../assets/location.svg";
import SkeletonLoader from "../Skeleton_loader/skeleton";
import { HotDeals } from "../../Api/packages";

export default function Hotdeals(){
    const [hotdeals, setHotdeals] = useState([]);
    const [loading, setLoading] = useState(true);
    const handleFetch = useCallback((data) => {
        setHotdeals(data || []);
        setLoading(false);
    }, []);
    useEffect(() => {
        setLoading(true);
    }, []);
    return(
        <>
        <section className="hotdeals">
            <div className="container-fluid">
                <div className="w90">
                    <div className="row align-items-center">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-7 col-7">
                            <div className="hotdealsheading">
                                <h2>Hot Deals</h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-5 col-5">
                            <div className="hotdealsbtns">
                                <Link to="/packages/">View All</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="w90">
                    <div className="hotdealsarea">
                        <HotDeals onFetch={handleFetch} />
                        {!loading ? (
                            <Swiper modules={[Navigation, Pagination, A11y]} spaceBetween={50} slidesPerView={4} navigation pagination={{ clickable: true }} breakpoints={{0: {slidesPerView: 1,},400: {slidesPerView: 1,},639: {slidesPerView: 1,},768: {slidesPerView: 2,},800: {slidesPerView: 2,},1024: {slidesPerView: 3,},1440: {slidesPerView: 3,},1700: {slidesPerView: 4,}}}>
                            {hotdeals.map((hotdeal, index) => hotdeal && (
                                    <>
                                    {index === 1 && (
                                        <SwiperSlide key="slider2">
                                            <div className="hotdealsdicount">
                                                <div className="discountcontetn">
                                                    <h2>Discounts Up to 50%</h2>
                                                    <p>Use your voucher code and save up!</p>
                                                    <div className="discountbtns">
                                                        <Link to="/packages/">View More</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    )}
                                    <SwiperSlide key={hotdeal.id}>
                                        <div className="newhotdelsgrid">
                                            <div className="hotdealsnewgimg">
                                                <Link to={hotdeal.package_url+'/?from=uk'} target="_blank"><img src={hotdeal.package_img} alt={hotdeal.package_name} /></Link>
                                            </div>
                                            <div className="hotgridcheading">
                                                <h2><Link to={hotdeal.package_url+'/?from=uk'} target="_blank">{hotdeal.package_name}</Link></h2>
                                            </div>
                                            <div className="hotgridmeta">
                                                <ul>
                                                    <li><img src={Calender} alt="calender"/><span className="metacontens">{hotdeal.day_nights}</span></li>
                                                    <li><img src={City} alt="calender"/><span className="metacontens">{hotdeal.total_city > 1 ? `${hotdeal.total_city} Cities` : `${hotdeal.total_city} City`}</span></li>
                                                    <li><img src={Location} alt="calender"/><span className="metacontens">{hotdeal.total_country > 1 ?`${hotdeal.total_country} Countries`:`${hotdeal.total_country} Country`}</span></li>
                                                </ul>
                                            </div>
                                            <div className="hotgridshort">
                                                <ul>
                                                    {hotdeal.package_display.split(',').map((packageItem, subIndex) => {
                                                        if (packageItem === 'hot_deals') {
                                                            return (
                                                            <li className="hotdealshots" key={`${index}-${subIndex}`}><Link to="#">Hot Deals</Link></li>
                                                            );
                                                        } if (packageItem === 'luxury_holiday') {
                                                            return (
                                                            <li key={`${index}-${subIndex}`}><Link to="#">Luxury Holiday</Link></li>
                                                            );
                                                        } if (packageItem === 'short_break') {
                                                            return (
                                                            <li key={`${index}-${subIndex}`}><Link to="#">Short Breaks</Link></li>
                                                            );
                                                        } if (packageItem === 'adventure_packages') {
                                                            return (
                                                            <li key={`${index}-${subIndex}`}><Link to="#">Adventure</Link></li>
                                                            );
                                                        }
                                                        return null;
                                                    })}
                                                </ul>
                                            </div>
                                            <div className="hotgridcontent">
                                                <p dangerouslySetInnerHTML={{ __html: hotdeal.package_content+'...' || '' }}></p>
                                            </div>
                                            <div className="hotgridpricebtn">
                                                <div className="hotgridpricebtns">
                                                    <div className="pricehotgrid">
                                                    {hotdeal.sale_price ? (
                                                        <>
                                                            <small>From <span><del>GBP <span className="pricelcous">£ {hotdeal.regular_price}</span></del></span></small>
                                                            <h5>GBP £ {hotdeal.sale_price} pp</h5>
                                                        </>
                                                    ) : (
                                                        <h5>GBP £ {hotdeal.regular_price} pp</h5>
                                                    )}
                                                    </div>
                                                    <div className="btnshotdel">
                                                        <Link to={hotdeal.package_url+'/?from=uk'} target="_blank">More Details</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                    </>
                                ))}
                            </Swiper>
                        ): (
                            <>
                            <SkeletonLoader count={4} />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}